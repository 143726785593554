export const removeDuplicates = (productSections) => {
  let uniqueObjects = [];
  let seenGids = new Set();
  productSections.forEach((section) => {
    uniqueObjects = [];
    seenGids.clear();
    section.data.forEach((product) => {
      if (!seenGids.has(product.gid)) {
        seenGids.add(product.gid);
        uniqueObjects.push(product);
      }
    });
    section.data = uniqueObjects;
  });

  return productSections;
};
