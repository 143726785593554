export const getCategoriesArray = (products = []) => {
  let categoriesArray = [];
  products.map((product) => {
    if (!categoriesArray.some((cat) => cat.gid === product.fk_product_family)) {
      categoriesArray.push({
        name: product.product_family_name,
        items: 1,
        gid: product.fk_product_family,
      });
    } else {
      categoriesArray.map((cat) => {
        if (cat.gid === product.fk_product_family) {
          cat.items+=1;
        }
      });
    }
  });

  return categoriesArray;
};
